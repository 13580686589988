import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import Layout from '../components/layout/Layout'
import Seo from '../components/Seo'
import Slide from '../components/slide/Slide'
import SlideItem from '../components/slide/SlideItem'
import BusinessModel from '../components/business-model/BusinessModel'
import BusinessModelItem from '../components/business-model/BusinessModelItem'
import ContactForm from '../components/contact-form/ContactForm'
import ServiceCard from '../components/service-card/ServiceCard'
import ServiceCardItem from '../components/service-card/ServiceCardItem'
import MainPoint from '../components/main-point/MainPoint'
import TrackingAnalytics from '../components/tracking-analytics/TrackingAnalytics'

import useSiteMetadata from '../hooks/useSiteMetadata'

import thumbnailImage from '../images/warpin-favicon.png'

const BizzyFieldForce = ({ data, location }) => {
	const [hash, setHash] = useState(location?.hash)
	const { siteUrl } = useSiteMetadata()

	const sliderData = data.wpgHeadlessQuery.acfSlideBanner.slideBannerItem
	const seoData = data.wpgHeadlessQuery.seo

	return (
		<Layout
			location={location}
			logo={data.logoImage}
			setHash={setHash}
			hash={hash}
		>
			<Seo
				title={seoData.title}
				canonical={`${siteUrl}${location.pathname}`}
				meta={[
					{ name: 'description', content: seoData.metaDesc },
					{ name: 'keywords', content: seoData.metaKeywords },
					{
						name: 'robots',
						content: `${seoData.metaRobotsNoindex}, ${seoData.metaRobotsNofollow}`,
					},
					{
						property: 'og:url',
						content: `${siteUrl}${location.pathname}`,
					},
					{ property: 'og:title', content: seoData.opengraphTitle },
					{
						property: 'og:description',
						content: seoData.opengraphDescription,
					},
					{ property: 'og:type', content: seoData.opengraphType },
					{
						property: 'og:site_name',
						content: seoData.opengraphSiteName,
					},
					{
						property: 'og:image',
						content:
							seoData.opengraphImage !== null
								? seoData.opengraphImage.localFile.publicURL
								: thumbnailImage,
					},
					{ name: 'twitter:title', content: seoData.twitterTitle },
					{
						name: 'twitter:description',
						content: seoData.twitterDescription,
					},
					{
						name: 'twitter:image',
						content:
							seoData.twitterImage !== null
								? seoData.twitterImage.localFile.publicURL
								: thumbnailImage,
					},
					{ name: 'twitter:card', content: 'summary' },
					{ name: 'twitter:site', content: '@warungpintarco' },
				]}
			/>

			<section className='slide-banner'>
				<Slide>
					{sliderData.map((itemData, index) => (
						<SlideItem
							key={index}
							title={itemData.title}
							content={itemData.content}
							buttonLabel={itemData.buttonLabel}
							buttonLink={itemData.buttonLink}
							logoSrc={getImage(itemData.logoSrc.localFile)}
							imageSrc={getImage(itemData.imageSrc.localFile)}
							imageXsSrc={getImage(itemData.imageXsSrc.localFile)}
							withButton={itemData.withButton}
							withLogo={true}
							logoSlim={true}
							bgPosition='center bottom'
						/>
					))}
				</Slide>
			</section>

			<section className='service-card'>
				<MainPoint
					title='Bizzy Field Force: asisten penjualan digital YANG membantu salesman'
					subtitle='Aplikasi Bizzy Field Force dirancang sebagai asisten penjualan digital yang membantu salesman melakukan pekerjaan dengan cerdas. Dibekali fitur-fitur cerdas seperti manajemen tugas, pemantauan insentif & algoritma canggih untuk menghasilkan data-data yang membantu salesman mengenal juragan'
					imageSrc={getImage(data.mainPoint.nodes[0])}
					imageAlt='wpg-banners'
					textColor='#FFFFFF'
					bgColor='#006CCA'
					height='700px'
				/>
			</section>

			<section className='service-card' id='manfaat'>
				<ServiceCard title='Mendukung Tugas Utama <br /> Salesforce Dalam 1 Aplikasi'>
					<ServiceCardItem
						title='Melakukan <br /> Kunjungan Harian'
						subtitle='Melakukan penjualan, penagihan, survei, <br /> pendataan stok barang, dan penerimaan <br /> retur barang dari konsumen'
						subtitleMobile='Melakukan penjualan, penagihan, survei, pendataan stok barang, dan penerimaan retur barang dari konsumen'
						imageSrc={getImage(data.serviceCard.nodes[0])}
						imageXsSrc={getImage(data.serviceCard.nodes[3])}
						imageAlt='wpg-illustration'
					/>
					<ServiceCardItem
						title='Mendaftarkan toko <br /> baru menjadi konsumen'
						subtitle='Akses untuk mendaftarkan toko baru <br /> yang terhubung langsung dengan <br /> NOO Portal'
						subtitleMobile='Akses untuk mendaftarkan toko baru yang terhubung langsung dengan NOO Portal'
						imageSrc={getImage(data.serviceCard.nodes[1])}
						imageXsSrc={getImage(data.serviceCard.nodes[4])}
						imageAlt='wpg-illustration'
					/>
					<ServiceCardItem
						title='Melakukan Kunjungan <br /> Tambahan'
						subtitle='Kunjungan diluar rute atau plan, <br /> seperti permintaan dari konsumen'
						subtitleMobile='Kunjungan diluar rute atau plan, seperti permintaan dari konsumen'
						imageSrc={getImage(data.serviceCard.nodes[2])}
						imageXsSrc={getImage(data.serviceCard.nodes[5])}
						imageAlt='wpg-illustration'
					/>
				</ServiceCard>
			</section>

			<section className='business-model'>
				<BusinessModel
					title='Lebih Dari Sekedar Melakukan Kunjungan'
					bgImageSrc={getImage(data.businessModel.nodes[3])}
				>
					<BusinessModelItem
						title='Menjaga <br /> Hubungan Dengan Konsumen'
						imageSrc={getImage(data.businessModel.nodes[4])}
						imageAlt='wpg-illustrator'
						isBFF={true}
					/>
					<BusinessModelItem
						title='Menjadi Penghubung <br /> Antara Distributor Dan Konsumen'
						imageSrc={getImage(data.businessModel.nodes[5])}
						imageAlt='wpg-illustrator'
						isBFF={true}
					/>
				</BusinessModel>
			</section>

			<section className='service-card'>
				<MainPoint
					title='Melacak informasi <br /> DAN Pemesanan <br /> Konsumen <br /> dengan lengkap'
					subtitle='Mempunyai visibilitas terhadap <br /> konsumen, mulai dari informasi, <br /> riwayat pesanan, status pesanan <br /> hingga status kredit konsumen'
					imageSrc={getImage(data.mainPoint.nodes[1])}
					imageXsSrc={getImage(data.mainPoint.nodes[2])}
					isImageXs={true}
					imageAlt='wpg-banners'
					textColor='#000000'
					bgColor='#FFEFBB'
					height='800px'
				/>
			</section>

			<section className='tracking-analytics' id='fitur'>
				<TrackingAnalytics
					title='Pelacakan analitis performa <br /> salesman yang terintegrasi dengan <br /> <span class="highlight">Bizzy Connect</span>'
					titleMobile='Pelacakan <br /> analitis performa salesman yang terintegrasi dengan <br /> <span class="highlight">Bizzy Connect</span>'
					subtitle1='BFF Daily Monitor'
					text1='Lacak seluruh kinerja salesforce, mulai dari lokasi, kunjungan dan performa'
					subtitle2='ulasan kinerja'
					text2='Tinjau performa salesforce secara harian maupun bulanan untuk membantu mereka mencapai target'
					imageSrc={getImage(data.trackingAnalytics.nodes[0])}
					imageXsSrc={getImage(data.trackingAnalytics.nodes[1])}
					imageAlt='wpg-banners'
				/>
			</section>

			<section className='contact-form'>
				<ContactForm
					title='Tertarik dengan BFF?'
					subtitle='Hubungi kami! Kami bantu meningkatkan bisnis Anda!'
					endpoint='https://getform.io/f/e403cc8a-9b83-4ef4-8af7-cd86c7a99c78'
					lang={false}
					isBFF={true}
					imageSrc={getImage(data.contactBackgrounds.nodes[2])}
					imageXsSrc={getImage(data.contactBackgrounds.nodes[6])}
					maxWidth={true}
				/>
			</section>
		</Layout>
	)
}

export const queryData = graphql`
	{
		wpgHeadlessQuery(slug: { eq: "bizzy-field-force" }) {
			id
			slug
			seo {
				canonical
				title
				metaDesc
				metaKeywords
				metaRobotsNofollow
				metaRobotsNoindex
				opengraphDescription
				opengraphSiteName
				opengraphTitle
				opengraphType
				opengraphUrl
				opengraphImage {
					localFile {
						publicURL
					}
				}
				twitterTitle
				twitterDescription
				twitterImage {
					localFile {
						publicURL
					}
				}
			}
			acfSlideBanner {
				slideBannerItem {
					buttonLabel
					buttonLink
					content
					title
					withButton
					logoSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1200)
							}
						}
					}
					imageXsSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1200)
							}
						}
					}
					imageSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1600)
							}
						}
					}
				}
			}
		}
		mainPoint: allFile(
			filter: { relativeDirectory: { eq: "banners/main-point" } }
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1440)
				}
			}
		}
		serviceCard: allFile(
			filter: { relativeDirectory: { eq: "illustrations/services-card" } }
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1240)
				}
			}
		}
		businessModel: allFile(
			filter: {
				relativeDirectory: { eq: "illustrations/business-model" }
			}
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1440)
				}
			}
		}
		trackingAnalytics: allFile(
			filter: { relativeDirectory: { eq: "banners/tracking-analytics" } }
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1240)
				}
			}
		}
		contactBackgrounds: allFile(
			filter: { relativeDirectory: { eq: "illustrations/contact-forms" } }
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1440)
				}
			}
		}
		logoImage: file(relativePath: { eq: "warpin-logo-inverse.png" }) {
			childImageSharp {
				gatsbyImageData(width: 800)
			}
		}
	}
`

export default BizzyFieldForce
